.footer { padding:60px 0; padding-bottom:0; padding-top:100px }
.footer .mainWidth { display:flex; gap:5%; }

.footer .mainWidth .footerBox {  }
.footer .mainWidth .footerBox b { display:block; font-size: 11pt; font-weight: 600; margin-bottom:5px; }
.footer .mainWidth .footerBox text { color:#888; font-size:10pt; }

.footer .mainWidth .footerBox.links ul li { list-style-type: disc;  list-style-position: inside; }
.footer .mainWidth .footerBox.links ul li a { color:#555; margin-left:-8px; }

.footer .mainWidth .footerBox.social nav { display:flex; gap:5px; }
.footer .mainWidth .footerBox.social nav a { padding:8px; background-color:#f2f3f5; font-size:16pt; line-height: 100%; }
.footer .mainWidth .footerBox.social nav a:hover { background-color:#e00667 ; color:#fff; }

.footerText { text-align: center; background-color: #f5f5f5; border-top:1px solid #e8e8e8; padding:20px 0; margin-top:60px; font-size:10pt; }
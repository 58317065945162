.backGMain { position:relative; height:650px; display: flex; flex-direction: column; }
.backGMain.small { height:380px; }

.backgroundVid { background-color: #000; position:absolute; z-index: -1; width:100%; height:100%; object-fit: cover; }
.aboveVideo { position:absolute; width:100%; height:100%; z-index: -1;  background-color: rgba(0,0,0,0.1); }

header {  box-shadow: 0 0 10px rgba(0,0,0,0.1);  }
header .headWidth { padding:10px; }

header nav { display:table; width:100%; border-bottom:1px solid #555a5f; background-color: rgba(0,0,0,0.4); padding:0px 0; }
header nav .mainWidth { display:flex; gap:10px; justify-content: space-between; }
header nav .mainWidth .logo { font-size:30pt; color:#fff; display:flex; align-items:center; }
header nav .mainWidth .logo img { height:52px }
header nav .mainWidth .left { display:flex; gap:20px; padding:10px 0; }
header nav .mainWidth .left a { color:#fff; font-size:12pt; font-weight:200; padding:10px 0;  }
header nav .mainWidth .left a.checked { font-weight: 800; }
header nav .mainWidth .left a svg { display:none; }
header nav .mainWidth .left a:hover { opacity: 0.7; }

.effect {  display: flex; flex:1;  }
.effect.small { height:300px; display:block; flex:0; }
.effect .textEffect {  max-width:40%;  float:left}
.effect .textEffect h1 { font-size:30pt; font-weight: 600; color:#fff; text-shadow:1px 1px #000; line-height: 100%; }
.effect .textEffect p { color:#fff; font-weight: 300; color:#fff; margin-top:20px; }
.effect .textEffect .btn { background-color:#e00667; padding:7px 20px; color:#fff; font-size:14pt; margin-top:20px; display:table; border-radius:5px; }

header nav .mainWidth .right {  display:flex; align-items: center; gap:15px; }
header nav .mainWidth .right a.link { display:flex; align-items: center; gap:3px; font-size:11pt; color:#eee;  }

header nav .mainWidth .right a.btn { font-weight: 600; background-color: #fd745a; color:#fff; padding:8px 20px; height:30px; display:table; border-radius: 20px; } 

.userBox { height:100%; padding:0 10px; display:flex; align-items: center; position:relative; cursor: pointer; }
.userBox:focus { box-shadow:inset 0px 0px 0px 1px #fff !important; }

.userBox:hover { background-color: rgba(255, 255, 255, 0.1); }
.userBox div { position:relative; color:#fff; display:flex; align-items: flex-end; }
.userBox div b { position:absolute; width:16px; height:16px; border-radius:50%; background-color: #c62827; right:-5px; top:-5px; font-size:8pt; line-height: 100%; display:flex; justify-content: center; align-items: center; }
.userBox div img { width:45px; aspect-ratio: 1; border-radius: 100px; object-fit: cover; }

/* .userBox:hover .userMenuBox { display:block; } */
.userBox .userMenuBox { width:220px; background-color: #befdff; box-shadow: 1px 1px 10px rgba(0,0,0,0.1); position:absolute; right:0; top:65px; z-index:2; }
.userBox .userMenuBox a { display:flex; gap:8px; font-size:10pt; align-items: center; font-size: 12pt; color:#366883; padding:8px 10px; border-top:1px solid #65e1e5; }
.userBox .userMenuBox a:hover { background-color: #fd745a; color:#fff; opacity:1; border-top:1px solid #de634b; }
.userBox .userMenuBox a svg { font-size:14pt;  }

.effect.small h1 { font-size: 30pt; margin-top:170px; color:#fff;  text-shadow:2px 2px #000; font-weight: 600; }
.effect.small .headPath { display:flex; align-items:end; gap:5px; font-size: 16pt; margin-top:200px; color:#fff;  text-shadow:2px 2px #000; font-weight: 600;  }
.effect.small .headPath a { color:#fff; line-height: 50%; }
.effect.small .headPath a.main { font-size:20pt; }

.languageSelectBox { position:relative }
.languageSelectBox li { cursor: pointer; background-color: rgba(255,255,255,0.8); padding:4px 5px 4px 10px; border-radius:15px; font-size:10pt; display:flex; align-items: center; color:#555 }
.languageSelectBox li:hover { opacity:0.8; }
.languageSelectBox template { display:none; flex-direction: column; gap:5px; position:absolute; top:48px; right:0; background-color: #fff; border-radius:0 0 10px 10px; padding:5px; }
.languageSelectBox template a { color:#555; font-weight: 600; font-size:10pt; text-align: center; }
.languageSelectBox template.open { display:flex;  }